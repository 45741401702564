<template>
    <div class="platon-sdbfooter">
        <div class="platon-sdbfooter__copyright">
            <a
                class="platon-sdbfooter__copyright-url"
                :href="$const.COPYRIGHT_URL ? $const.COPYRIGHT_URL : '/#!'"
                target="_blank"
            >
                {{ `© ${$const.IN_SITE_COPYRIGHT}` }}
            </a>
        </div>
        <div class="platon-sdbfooter__versions">
            <p class="platon-sdbfooter__version">{{ $appVersion }}</p>
            <p class="platon-sdbfooter__version">{{ $projectInfo.version }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarFooter"
}
</script>
